/* autoprefixer grid: autoplace */

// Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
@import "~simple-line-icons/dist/styles/simple-line-icons.css";
// Import Main styles for this application
@import "./scss/style.scss";
// Temp fix for reactstrap
@import "./scss/core/_dropdown-menu-right.scss";

@import "scss/sig.scss";
