
.textComponent {
  color: blue;
  padding: 1px;
  textBaseline: middle
}

.runningLine {
  color: blue;
  background: white;
  direction: ltr;

}

.numberComponent {
  color: red;
  padding: 1px;
}

.smooth-container {
  scroll-behavior: smooth;
}
