$ft-cell-height: 3.2rem;

$ft-cell-letter-height: 2*$ft-cell-height/3;
$ft-cell-index-height: 1*$ft-cell-height/3;

$ft-window-k: 1.2;

$ft_sizes: (
        2: 4.5rem,
        3: 4.2rem,
        4: 3.8rem,
        5: 3.4rem,
        6: 3.1rem,
        7: 2.7rem,
        8: 2.5rem,
        9: 2.3rem,
        10: 2.2rem
);

@each $ft_dimension, $ft_base_height2 in $ft_sizes {
  .ft#{$ft_dimension}-cell {
    height: 100%;
    font-size: 0.6*$ft_base_height2;
  }

  .ft#{$ft_dimension}-cell-letter, .paper-ft#{$ft_dimension}-cell-letter {
    height: percentage(2/3);
    font-size: 2*$ft_base_height2/(3*1.6);
  }

  .ft#{$ft_dimension}-cell-index, .paper-ft#{$ft_dimension}-cell-index {
    height: percentage(1/3);
    font-size: 1*$ft_base_height2/(3*1.6);
  }

  .ftw, .ftw-pp-0, .ftw-pp-9 {
    .ft#{$ft_dimension}-cell {
      font-size: $ft-window-k*0.6*$ft_base_height2;
    }

    .ft#{$ft_dimension}-cell-letter {
      font-size: $ft-window-k*2*$ft_base_height2/(3*1.6);
    }

    .ft#{$ft_dimension}-cell-index {
      font-size: $ft-window-k*1*$ft_base_height2/(3*1.6);
    }
  }

  .ft#{$ft_dimension}-row {
    height: percentage(1/$ft_dimension);
  }

  .ft-solid#{$ft_dimension} {
    height: $ft_dimension * $ft_base_height2;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.ones-open, .ones-open-m {
  background: white;
  color: red;

  .ft-cell-index {
    color: #2da4e1;
  }

  .ft-cell-index-letter {
    color: red;
  }
}

.ftw.ones-open {
  background: rgba(255, 255, 255, 0.7);
  color: red;

  .ft-cell-index {
    color: #2da4e1;
  }
}

.th-sa2 {
  .ftw.ones-open {
    background: rgba(255, 255, 255, 0.9);
    color: red;

    .ft-cell-index {
      color: blue;
    }
  }

  .ftw.ones-open-after {
    background: rgba(255, 255, 255, 0.9);
    color: blue;
  }

  .ftw.ones-lost {
    background: rgba(255, 255, 255, 0.9);
    color: transparent;
    font-size: 0 !important;

    .ft-cell-index {
      color: blue;
    }
  }

  .ftw.ones-close, .ftw-pp-0.ones-close, .ftw-pp-0.ft-adjacent.ones-close {
    background: transparent;
    color: blue;
  }

  .ftw.ones-close-after, .ftw-pp-0.ft-adjacent.ones-close-after {
    background: rgba(200, 200, 200, 0.7);
    color: white;
  }
}

.paper.ones-open {
  background: white;
  color: black;
  font-weight: bold;
  //  text-decoration: underline;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='black' stroke-width='2'/><path d='M0 99 L99 0 L100 1 L1 100' fill='transparent' stroke='black' stroke-width='2'/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}

.ones-selected {
  background: white;
  color: #2da4e1;

  animation: anisel .3s ease-in-out alternate;
}

.paper.ones-selected {
  background: white;
  color: black;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='black' stroke-width='2'/><path d='M0 99 L99 0 L100 1 L1 100' fill='transparent' stroke='black' stroke-width='2'/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
}

.ones-close, .ones-close-after, .ones-close-m {
  background: linear-gradient(to bottom, #2DA4E1, #0F58B6);
  color: white;
}

.ftw.ones-close, .ftw.ones-close-after, .ftw-pp-0.ones-close, .ftw-pp-0.ft-adjacent.ones-close, .ftw-pp-0.ft-adjacent.ones-close-after {
  background: transparent;
  color: white;
}

.ftw-pp-9.ft-adjacent.ones-close, .ftw-pp-9.ft-adjacent.ones-close-after {
  background: linear-gradient(to bottom, rgba(45, 164, 225, 1.0), rgba(15, 88, 182, 1.0));
}

.ftw.ft-adjacent.ones-close, .ftw.ft-adjacent.ones-close-after {
  background: linear-gradient(to bottom, rgba(45, 164, 225, 0.7), rgba(15, 88, 182, 0.7));
}

.paper.ones-close {
  background: white;
  color: black;
}

.paper.ones-close2 {
  background: white;
  color: black;
  font-size: .875rem;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.ones-open-after, .ones-open-after-m {
  background: white;
  color: #2da4e1;
}

.ftw.ones-open-after {
  background: rgba(255, 255, 255, 0.7);
  color: #2da4e1;
}

.paper.ones-open-after {
  background: white;
  color: black;
  font-weight: bold;
  //  text-decoration: underline;
}

.ones-lost {
  //  background: linear-gradient(to bottom, #FF9A96, #D30A09);
  background: white;
  color: transparent;
  //  font-size: 0 !important;

  .ft-cell-index {
    color: #2da4e1;
  }
}

.adjacent-hint.ones-lost, .adjacent-hint.ones-open {
  color: #2da4e1;
}

.ftw.ones-lost {
  //  background: linear-gradient(to bottom, rgba(255, 154, 150, 0.7), rgba(211, 10, 9, 0.7));
  background: rgba(255, 255, 255, 0.7);
  color: transparent;
  font-size: 0 !important;

  .ft-cell-index {
    color: #2da4e1;
  }
}

.paper.ones-lost {
  color: black;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='black' stroke-width='2'/><path d='M0 99 L99 0 L100 1 L1 100' fill='transparent' stroke='black' stroke-width='2'/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;
  font-size: .875rem !important;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.ones-info-letter {
  background: white;
  color: red;
}

.ones-info-number {
  background: white;
  color: #2da4e1;
}

.ones-open-m, .ones-close-m, .ones-open-after-m {
  font-size: 0.85rem;
  text-align: center;
  vertical-align: baseline;
  width: 1.1rem;
  border: #C0CADD solid 1px;
  border-radius: 0.2rem;
}

.paper-ft-cell-index {
  font-weight: normal;
}

.ft-cell-index {
  font-weight: normal;
  text-align: left;
}

.ones-open, .ones-close, .ones-close2, .ones-close-after, .ones-lost, .ones-selected, .ones-info-letter, .ones-info-number, .ones-open-after {
  text-align: center;
  vertical-align: baseline;
}

.ftw, .ftw-pp-9, .ftw-pp-0 {
  &.ones-open, &.ones-close, &.ones-close2, &.ones-close-after, &.ones-lost, &.ones-selected, &.ones-info-letter, &.ones-info-number, &.ones-open-after {
    border: rgba(192, 202, 221, 1.0) solid 1px;
    border-radius: 0;
  }
}

.ftnw {
  &.ones-open, &.ones-close, &.ones-close2, &.ones-close-after, &.ones-lost, &.ones-selected, &.ones-info-letter, &.ones-info-number, &.ones-open-after {
    border: #C0CADD solid 2px;
    border-radius: 0.4rem;
  }
}

.ft-blink {
  animation: bbl 1s ease-in-out infinite alternate;
}

@keyframes bbl {
  0% {
    transform: scale(1.0) translateX(0) translateY(0);
  }
  100% {
    transform: scale(1.5) translateX(0.15rem) translateY(0.15rem);
  }

  0%, 40% {
    color: red;
  }
  60%, 100% {
    color: blue;
  }
}

@keyframes anisel {
  0%, 100% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.2);
  }
}

@keyframes ani-window {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.ft-window {
  position: relative;
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.ft-window-img {
  z-index: 7;
  left: 0;
  top: 0;
  width: 100%;
  //  height: 100%;
  //  padding-left: 0;
  //  padding-right: 0;
  //    padding-left: -15px;
  //  padding-right: -15px;
}

.ftw-pp-9.ft-window-img {
  opacity: 0;
}

.ft-window-over {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.you.ft-table-cell {
  background: #ffb8b8 !important;
  color: green;
}

.rna.ft-table-cell {
  background: #FEFACD !important;
  color: green;
}
