$onex-cell-height: 3.2rem;
$onex-info-background: #d8d8d8;
$onex-cell-background: #099115;
$onex-cell-color-yellow: #f0ff00;

.onex-axis {
  background: $onex-info-background;
  color: blue;
}

.onex-number {
  background: $onex-cell-background;
  color: $onex-cell-color-yellow;
}

.onex-number-zero-fail {
  background: red;
  color: $onex-cell-color-yellow;
}

.onex-number-empty-selected, .onex-number-onex-zero-selected {
  color: white;
  border: $onex-cell-color-yellow solid 3px !important;
}

.onex-number-empty-selected {
  background: $onex-cell-background;
}

.onex-number-onex-zero-selected {
  background: red;
}

.onex-cell {
  position: relative;
  border: #C0CADD solid 2px;
  border-radius: 0.4rem;
}

.onex-cell:before {
  content: "";
  display: block;
  padding-top: 100%; /* ratio of 1:1*/
}

.onex-cell-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@each $frx_theme, $frx_column in $frx_sizes {
  .frx-font-size-#{$frx_column} {
    font-size: calc(1.4rem + #{5/$frx_column}rem);
  }
}

.onex-icon {
  width: 75%;
}

.onex-logo {
  width: 110%;
}