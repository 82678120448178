// core overrides

$border-color:                      $gray-200;

// Navbar

$navbar-height:                     60px;

$navbar-brand-width:                250px;
$navbar-brand-bg:                   theme-color("primary");
$navbar-brand-logo-size:            90px auto;
$navbar-brand-border: (
    bottom: (
        size:                       1px,
        style:                      solid,
        color:                      darken(theme-color("primary"),10%)
    )
);

$navbar-color:                      $gray-300;
$navbar-hover-color:                darken($navbar-color, 10%);
$navbar-active-color:               darken($navbar-color, 20%);
$navbar-disabled-color:             lighter($navbar-color, 20%);

// Sidebar

$sidebar-width:                     250px;
$sidebar-bg:                        $gray-800;
$mobile-sidebar-width:              250px;

// Sidebar Navigation

$sidebar-nav-title-padding-y:       .95rem;
$sidebar-nav-title-color:           $text-muted;

$sidebar-nav-link-hover-bg:         darken($sidebar-bg, 5%);

$sidebar-nav-link-active-bg:        darken($sidebar-bg, 5%);

$sidebar-nav-dropdown-bg:           darken($sidebar-bg, 3%);

// Breadcrumb

$breadcrumb-margin:                 0;
$breadcrumb-borders:                0;
