$fd-cell-height: 1.4rem;
$fd_gate_size: 1.2rem;

.fd-cell {
  position: relative;
  z-index: 20;
  cursor: pointer;
}

.fd-central {
  border: black solid 1px;
}

.fd-active {
  border: lightgray solid 1px;
}

.fd-result {
  font-size: 1.2rem;
}

.fd-gate-top {
  height: $fd_gate_size;
}

.fd-gate-side {
  flex: 0 0 $fd_gate_size;
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: $fd_gate_size;
  width: 100%;
}

.fd-gate-inside {
  flex: 0 0 calc(100% - #{2 * $fd_gate_size});
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: calc(100% - #{2 * $fd_gate_size});
  width: 100%;
}

.fd-gate1 {
  height: 2 * $fd-cell-height;
}

.fd-gate2 {
  height: $fd-cell-height;
}

@mixin make-col-fd($size, $columns, $central_size,  $central_columns) {
  flex: 0 0 #{(100% - percentage(2 * $central_size / $central_columns)) / (2 * $columns)};
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: #{(100% - percentage(2 * $central_size / $central_columns)) / (2 * $columns)};
}

.fd-col-fd4 {
  @include make-col-fd(1, 4, 1, 10)
}

.fd-col-fd2 {
  @include make-col-fd(1, 2, 1, 10)
}
