
$xmt-header-top-height: 3rem;
$xmt-header-info-height: 5rem;
$xmt-footer-turn-height: 2.5rem;
$xmt-footer-status-height: 2.5rem;
$xmt-footer-button-height: 3rem;

$xmt-cell-height: calc((100vh - #{$xmt-header-top-height + $xmt-header-info-height + $xmt-footer-turn-height + $xmt-footer-status-height + $xmt-footer-button-height}) / 12);
$xmt-moon-height: calc(#{$xmt-cell-height} * 1.1);

$xmt-blue: #0f58b6;

$xmt-col-info-width: percentage(2 / 12);

.xmt-field-outer {
  position: relative;
  background: $xmt-blue;
  width: 100%;
}

.xmt-field {
  position: absolute;

  //  background: #0f58b6;

  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  img {
    margin-left: $xmt-col-info-width;
    margin-right: $xmt-col-info-width;
  }
}

.xmt-tree {
  position: absolute;
  z-index: 7;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-left: $xmt-col-info-width;
  padding-right: $xmt-col-info-width;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.xmt-help-text {
  text-align: left;
  color: blue;
  background: white;

  p {
    padding-left: 0.9rem;
    margin-bottom: 0.137rem;
  }

  ol {
    padding-left: 0.9rem;
  }

  ul {
    margin-bottom: 0;
  }

  .button-right {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .button-left {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

.xmt-legend {
  position: absolute;
  z-index: 50;
  width: auto;
  font-size: 1.7rem;
  right: $xmt-col-info-width + 4%;
  top: 3%;
}

.xmt-moon {
  position: absolute;
  z-index: 5;
  height: $xmt-moon-height;
  min-height: 1.1rem;
  max-height: 2.5rem;
  width: auto;
  left: 4%;
  top: 3%;
}

.xmt-star {
  position: absolute;
  z-index: 3;
  height: 0.45rem;
  width: auto;
  animation: fadeInFromNone2 15s infinite ease-out;
}

.xmt-snow {
  position: absolute;
  z-index: 100;
  height: 0.55rem;
  width: auto;
  animation: fadeInFromNone 15s infinite ease-out;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0) rotate(3deg);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0) rotate(-5deg);
  }

  30%, 50%, 70% {
    transform: translate3d(-3px, 0, 0) rotate(10deg);
  }

  40%, 60% {
    transform: translate3d(3px, 0, 0) rotate(-10deg);
  }
}

@keyframes fadeInFromNone2 {
  0% {
    display: block;
    opacity: 0.4;
  }

  70% {
    display: block;
    opacity: 1;
    transform: rotate3d(1, 1, 2, 100deg);
  }

  100% {
    display: block;
    opacity: 0.5;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  70% {
    display: block;
    opacity: 1;
    transform: translate3d(10px, 20px, 0);
  }

  100% {
    display: block;
    opacity: 0;
    transform: translate3d(5px, 30px, 0);
  }
}

.xmt-gift-1, .xmt-gift-2, .xmt-gift-3, .xmt-gift-4 {
  position: absolute;
  z-index: 100;
  height: 45%;
}

.xmt-gift-1 {
  bottom: 1px;
  left: 1px;
}

.xmt-gift-2 {
  bottom: 1px;
  right: 1px;
}

.xmt-gift-3 {
  top: 1px;
  left: 1px;
}

.xmt-gift-4 {
  top: 1px;
  right: 1px;
}

.xmt-ball {
  position: absolute;
  z-index: 30;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 80%;
}

.xmt-animated-c {
  position: absolute;
  /*
  top: 0;
  right: 0;
    height: 100%;
  */
}

.xmt-animated-m {
  bottom: 5%;
  left: 0;
  //  transform: translate(-50%, 0);
  height: 90%;
}

.xmt-ball-right, .xmt-ball-left {
  position: absolute;
  bottom: 5%;
  height: 90%;
}

.xmt-ball-right.waiting, .xmt-ball-left.waiting {
  bottom: 15%;
  height: 70%;
}

.xmt-ball-right.active {
  bottom: 0;
  height: 100%;
  animation: shake 10s infinite cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.xmt-ball-right {
  right: 5%;
}

.xmt-ball-left {
  left: 5%;
}

.xmt-block {
  position: absolute;
  top: 20%;
  height: 60%;
  right: 5%;
}

.xmt-cell {
  position: relative;
  height: $xmt-cell-height;
  min-height: 1.3rem;
  max-height: 2.3rem;
  z-index: 20;
  cursor: pointer;
}

.xmt-info-active {
  color: yellow !important;
}

.xmt-info-left, .xmt-info-right {
  border-top: 1px solid lightgrey;
  color: white;
  position: relative;
}

.xmt-info-left {
  border-right: 1px solid lightgrey;
}

.xmt-info-right {
  border-left: 1px solid lightgrey;
}

.xmt-info-status {
  background: $xmt-blue;
  color: white;
  text-align: center;
}

.xmt-info-turn-failure2 {
  background: yellow;
  color: red;
  text-align: center;
}

.xmt-info-turn-failure {
  background: red;
  color: white;
  text-align: center;
}

.xmt-info-turn-success {
  background: green;
  color: yellow;
  text-align: center;
}

.xmt-info-turn-none {
  background: yellow;
  color: green;
  text-align: center;
}

.xmt-player-cell-b {
  background: yellow;
  color: green;
}

.xmt-player-cell {
  color: green;
}

