.ap8-main {
  max-width: 800px;
  background: #ceebe4;
  padding: 10px 10px;
}

//-----------------------------------------------------------------------
//Убираем обводку вокруг кнопки, которая появляется при нажатии на кнопку
//-----------------------------------------------------------------------

button:focus {
  outline: none;
}

/*
button::-moz-focus-inner {
  border: 0;
}*/

//Конеу "Убираем обводку..."---------------------------------------------

$ap8_border: 10%;
$ap8_window_bus: 10%;
$ap8_window_com: 5.1%;
$ap8_window_eco: 4.3%;
$color_sky: #7bd5ff;
$color_blue_sky: #00aced;
$color_dark_brown: rgba(80, 60, 36, 1.0);
$color_dark_light: rgba(233, 148, 41, 1.0);
$color_break: rgb(188, 170, 156);
$color_grey: rgb(143, 143, 143);
$ap8_pilot_height: 1.875rem;
$ap8_date_time_height: 1.1rem;

.ap8 {
  overflow-x: hidden;

  .main {
    padding-top: 0;
    margin-left: 0;
  }

  .sidebar8 {
    position: absolute;
    z-index: 2000;

    color: $sidebar-color;
    background: $sidebar-bg;
    width: $sidebar-width;
    height: 100%;

    .nav-item {
      text-align: left;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .button-right {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .tooltip {
    z-index: 300;
  }

  .tooltip-inner {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4) !important;
    background: white;
    color: red;
    border-radius: 6px;
  }

  .arrow::before {
    border-bottom-color: white !important;
    border-top-color: white !important;
  }

  .help-text {
    text-align: left;
    color: blue;
    background: white;

    p {
      padding-left: 0.9rem;
      margin-bottom: 0.137rem;
    }

    ol {
      padding-left: 0.9rem;
    }

    ul {
      margin-bottom: 0;
    }

    .button-right {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

    .button-left {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}

.ap8-header {
  position: relative;

  .logo {
    position: absolute;
    height: $ap8_pilot_height;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .date, .time {
    position: absolute;
    bottom: -2px;
  }

  .date {
    left: -($ap8_pilot_height + $ap8_date_time_height)/2;
    transform: translate(-100%, 0);
    min-width: 6rem;
    text-align: right;
  }

  .time {
    right: -($ap8_pilot_height + $ap8_date_time_height)/2;
    transform: translate(100%, 0);
    min-width: 6rem;
    text-align: left;
  }
}

.ap8_plane_front {
  position: relative;
  background: $color_sky;

  .side-toggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: transparent;
    font-size: 1.1rem;
  }

  .side-search {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    background: transparent;
    font-size: 1.1rem;
  }

  .cockpit {
    position: relative;
    clip-path: url('#fwClip');

    .panel {
      background: rgb(71, 91, 122);
      color: white;
      min-height: $ap8_pilot_height;
    }

    .pilot {
      &-left, &-right {
        position: absolute;
        height: $ap8_pilot_height;
        bottom: 0;
      }

      &-left {
        left: 0;
      }

      &-right {
        right: 0;
        transform: scaleX(-1);
      }
    }
  }

  .top {
    background: white;
  }

  .panel2 {
    background: rgb(71, 91, 122);
    color: white;
    min-height: $ap8_date_time_height;
  }

  .deck {
    background: #ddcec1;

    .nickname {
      position: absolute;
      left: 0.25rem;
    }

    .wallet {
      position: absolute;
      right: 0.25rem;
    }
  }
}

.ap8_wing {
  padding-top: 70%;
  background: $color_sky;
}

@mixin ap8_make_width($prefix, $max, $current, $cls, $window, $correct) {
  .#{$prefix}--#{$max}-#{$current}_#{$cls} {
    flex: 0 0 $current * (100% - $ap8_border - 2 * $window) / $max + $correct;
    max-width: $current * (100% - $ap8_border - 2 * $window) / $max + $correct;
  }
}

.ap8_br_front {
  @include ap8_make_width(ap8_br_info, 4, 2, bus, $ap8_window_bus, $ap8_window_bus);
  @include ap8_make_width(ap8_br_info, 5, 3, com, $ap8_window_com, $ap8_window_com);
  @include ap8_make_width(ap8_br_info, 5, 2, com, $ap8_window_com, $ap8_window_com);
  @include ap8_make_width(ap8_br_info, 6, 3, eco, $ap8_window_eco, $ap8_window_eco);

  .ap8_br_info_0 {
    position: relative;
    flex: 0 0 $ap8_border;
    max-width: $ap8_border;
    background: #968260;

    .shtorka {
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        margin-left: -35%;
        width: 135%;
        height: 100%;
      }
    }

    .tticker {
      position: absolute;

      font-size: 0.875rem;
      color: #ff0000;
      top: -2%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.ap8_br_back {
  flex: 0 0 100%;
  max-width: 100%;
  background: #968260;
  min-height: 3.5rem;
}

.ap8_br {
  position: relative;
  flex: 0 0 $ap8_border;
  max-width: $ap8_border;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'> <rect width='100%' height='100%' fill='rgb(188, 170, 156)'/><line x1='8' y1='0' x2='8' y2='100' stroke='rgb(143, 143, 143)' stroke-width='4'/><line x1='92' y1='0' x2='92' y2='100' stroke='rgb(143, 143, 143)' stroke-width='4'/></svg>");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%, auto;

  .stewardess {
    background: url("/img/g/ap8/stewardess_red.svg");
    background-size: 500% 1600%;

    &.bus {
      top: -62%;
      width: 400%;
      height: 100%;
    }

    &.eco {
      top: -70%;
      width: 400%;
      height: 115%;
    }

    &.com {
      top: -62%;
      width: 400%;
      height: 110%;
    }

    pointer-events: none;
    position: absolute;
    left: 50%;
    z-index: 20;

    display: inline-block; /* Display icon as inline block */
    transform: translate(-50%, 0) scale(1.10);

    &.fun {
      background-position: -200% -1503.5%;
    }

    &.L1T {
      background-position: -200% -1510.5%;
    }

    &.L1A {
      background-position: -200% -1518.0%;
    }

    &.R1A {
      background-position: -200% -1525.0%;
    }

    &.L2C {
      background-position: -200% -1531.5%;
    }

    &.L2T {
      background-position: -200% -1539.0%;
    }

    &.R1T {
      background-position: -200% -1546.0%;
    }

    &.R2C {
      background-position: -200% -1552.5%;
    }

    &.R2A {
      background-position: -200% -1560.0%;
    }

    &.L1C {
      background-position: -200% -1567.0%;
    }

    &.L2A {
      background-position: -200% -1576.0%;
    }

    &.R1C {
      background-position: -200% -1583.5%;
    }

    &.R2T {
      background-position: -200% -1590.5%;
    }

    &.none {
      background-position: -200% -1597%;
    }
  }
}

.ap8_place_4_2 {
  position: relative;

  flex: 0 0 (100% - $ap8_border - 2 * $ap8_window_bus)/2;
  max-width: (100% - $ap8_border - 2 * $ap8_window_bus)/2;
  background: #968260;

  .chair {
    height: 100%;
    width: 100%;
  }

  .table8 {
    height: 100%;
    width: 100%;
  }
}

.ap8_place_5_2 {
  flex: 0 0 2*(100% - $ap8_border - 2*$ap8_window_com)/5;
  max-width: 2*(100% - $ap8_border - 2*$ap8_window_com)/5;
  background: #968260;

  .chair {
    height: 100%;
    width: 100%;
  }
}

.ap8_place_5_3 {
  flex: 0 0 3*(100% - $ap8_border - 2*$ap8_window_com)/5;
  max-width: 3*(100% - $ap8_border - 2*$ap8_window_com)/5;
  background: #968260;

  .chair {
    height: 100%;
    width: 100%;
  }
}

.ap8_place_6_3 {
  flex: 0 0 (100% - $ap8_border - 2 * $ap8_window_eco)/2;
  max-width: (100% - $ap8_border - 2 * $ap8_window_eco)/2;
  background: #968260;

  .chair {
    height: 100%;
    width: 100%;
  }
}

.ap8_w5 {
  flex: 0 0 $ap8_window_com;
  max-width: $ap8_window_com;

  img {
    width: 100%;
    height: 100%;
  }
}

.ap8_w6 {
  flex: 0 0 $ap8_window_eco;
  max-width: $ap8_window_eco;

  img {
    width: 100%;
    height: 100%;
  }
}

.ap8_w4 {
  flex: 0 0 $ap8_window_bus;
  max-width: $ap8_window_bus;

  img {
    width: 100%;
    height: 100%;
  }
}

.ap8_row {
  position: relative;

  .question {
    position: absolute;
    bottom: -5%;
    left: 50%;
    transform: translate(-50%, 100%);

    width: 98%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    z-index: 200;

    .var {
      flex: 0 0 percentage(1/3);
      max-width: percentage(1/3);

      .info {
        cursor: pointer;
        padding-top: 2%;
        padding-bottom: 2%;
        margin: 2%;

        width: 96%;
        color: white;
        background: $color_blue_sky;
        border-radius: 5px;
        border: $color_blue_sky solid 1px;

        &.selected {
          color: red;
          background: white;
        }
      }
    }
  }
}

.ap8_seat {
  position: relative;
  cursor: pointer;

  .info {
    &.selected {
      background: white;
      color: red;
    }

    &.enable {
      background: white;
      color: black;
    }

    &.win {
      background: green;
      color: yellow;
    }

    &.good {
      background: yellow;
      color: green;
    }

    &.bad {
      background: red;
      color: white;
    }

    &.lost {
      background: white;
      color: $color_blue_sky;
      border: red 1px solid;
    }

    &.drop {
      background: url('/img/diag-red.svg') no-repeat center center;
      background-size: 100% 100%, auto;
      z-index: 2;
    }

    &.water {
      background: $color_blue_sky;
      color: white;
    }

    &.coffee {
      background: $color_dark_brown;
      color: white;
    }

    &.tea {
      background: $color_dark_light;
      color: white;
    }

    color: lightgrey;
    position: absolute;

    width: 90%;
    bottom: 1px;
    border: transparent 1px solid;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    font-size: 0.60rem;
    border-radius: 5px;
    pointer-events: none;
  }

  .info2 {
    font-size: 0.75rem;
    pointer-events: none;
    color: white;
    position: absolute;
    width: 90%;
    top: 1%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 20;
  }

  .passenger {
    z-index: 10;
    pointer-events: none;
  }

  &.cab_bus {
    .Man01 {
      &-d, &-m {
        position: absolute;
        height: 107%;
        top: -39%;
      }

      &-d {
        left: 50%;
        transform: translate(-50%, 0);
      }

      &-m {
        left: 0;
        transform: scaleX(-1) translate(-35%, 0);
      }
    }

    .Man02 {
      &-d, &-m {
        position: absolute;
        height: 101%;
        top: -37%;
      }

      &-d {
        left: 50%;
        transform: translate(-50%, 0);
      }

      &-m {
        left: 0;
        transform: scaleX(-1) translate(-9%, 0);
      }
    }

    .YMan2 {
      &-d, &-m {
        position: absolute;
        height: 75%;
        top: -15%;
      }

      &-d {
        left: 55%;
        transform: translate(-45%, 0);
      }

      &-m {
        left: 0;
        transform: scaleX(-1) translate(-7%, 0);
      }
    }

    .dGirl2 {
      &-d, &-m {
        position: absolute;
        height: 100%;
        top: -36%;
      }

      &-d {
        left: 50%;
        transform: translate(-50%, 0);
      }

      &-m {
        left: 0;
        transform: scaleX(-1) translate(-25%, 0);
      }
    }

    .aGirl {
      &-d, &-m {
        position: absolute;
        height: 117%;
        top: -37%;
      }

      &-d {
        left: 60%;
        transform: translate(-40%, 0);
      }

      &-m {
        left: 0;
        transform: scaleX(-1) translate(-12%, 0);
      }
    }

    .Girl01 {
      &-d, &-m {
        position: absolute;
        top: -25%;
        height: 85%;
      }

      &-d {
        left: 50%;
        transform: translate(-50%, 0);
      }

      &-m {
        left: 0;
        transform: scaleX(-1) translate(-50%, 0);
      }
    }

    .pass_yman {
      &-d, &-m {
        position: absolute;
        top: -37%;
        height: 100%;
      }

      &-d {
        left: 53%;
        transform: translate(-47%, 0);
      }

      &-m {
        left: 0;
        transform: scaleX(-1) translate(-9%, 0);
      }
    }
  }

  .pass2 {
    &.com {
      top: -43%;
      width: 125%;
      height: 140%;
    }

    &.eco {
      top: -50%;
      width: 120%;
      height: 140%;
    }

    background-image: url("/img/g/ap8/ps2.png");
    background-size: 900% 800%;

    pointer-events: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;

    display: inline-block;

    &.p_0_0 {
      background-position: -901% -0%;

      &.right {
        background-position: -879% -0%;
      }

      &.left {
        background-position: -889% -0%;
      }
    }

    &.p_0_1 {
      background-position: -303.5% -0%;

      &.right {
        background-position: -277.0% -0%;
      }

      &.left {
        background-position: -290.5% -0%;
      }
    }

    &.p_0_2 {
      background-position: -600.5% -0%;

      &.right {
        background-position: -575.0% -0%;
      }

      &.left {
        background-position: -587.5% -0%;
      }
    }

    &.p_1_0 {
      background-position: -901% -101%;

      &.right {
        background-position: -879% -101%;
      }

      &.left {
        background-position: -889% -101%;
      }
    }

    &.p_1_1 {
      background-position: -304% -100%;

      &.right {
        background-position: -277.5% -100%;
      }

      &.left {
        background-position: -291% -100%;
      }
    }

    &.p_1_2 {
      background-position: -600.5% -100%;

      &.right {
        background-position: -575.0% -100%;
      }

      &.left {
        background-position: -587.5% -100%;
      }
    }

    &.p_2_0 {
      background-position: -901% -201%;

      &.right {
        background-position: -879% -201%;
      }

      &.left {
        background-position: -889% -201%;
      }
    }

    &.p_2_1 {
      background-position: -304% -202%;

      &.right {
        background-position: -277.5% -202%;
      }

      &.left {
        background-position: -291% -202%;
      }
    }

    &.p_2_2 {
      background-position: -600% -200%;

      &.right {
        background-position: -574% -200%;
      }

      &.left {
        background-position: -587% -200%;
      }
    }

    &.p_3_0 {
      background-position: -901% -302%;

      &.right {
        background-position: -879% -302%;
      }

      &.left {
        background-position: -889% -302%;
      }
    }

    &.p_3_1 {
      background-position: -304% -303%;

      &.right {
        background-position: -277.5% -303%;
      }

      &.left {
        background-position: -291% -303%;
      }
    }

    &.p_3_2 {
      background-position: -600% -301%;

      &.right {
        background-position: -573% -301%;
      }

      &.left {
        background-position: -587% -301%;
      }
    }

    &.p_4_0 {
      background-position: -901% -401.5%;

      &.right {
        background-position: -879% -401.5%;
      }

      &.left {
        background-position: -889% -401.5%;
      }
    }

    &.p_4_1 {
      background-position: -304% -402%;

      &.right {
        background-position: -277.5% -402%;
      }

      &.left {
        background-position: -291% -402%;
      }
    }

    &.p_4_2 {
      background-position: -600% -401%;

      &.right {
        background-position: -573% -401%;
      }

      &.left {
        background-position: -587% -401%;
      }
    }

    &.p_5_0 {
      background-position: -901% -501.5%;

      &.right {
        background-position: -879% -501.5%;
      }

      &.left {
        background-position: -889% -501.5%;
      }
    }

    &.p_5_1 {
      background-position: -304% -502%;

      &.right {
        background-position: -277.5% -502%;
      }

      &.left {
        background-position: -291% -502%;
      }
    }

    &.p_5_2 {
      background-position: -600% -500%;

      &.right {
        background-position: -573% -500%;
      }

      &.left {
        background-position: -587% -500%;
      }
    }

    &.p_6_0 {
      background-position: -901% -601%;

      &.right {
        background-position: -879% -601%;
      }

      &.left {
        background-position: -889% -601%;
      }
    }

    &.p_6_1 {
      background-position: -304% -601%;

      &.right {
        background-position: -277.5% -601%;
      }

      &.left {
        background-position: -291% -601%;
      }
    }

    &.p_6_2 {
      background-position: -600% -600%;

      &.right {
        background-position: -573% -600%;
      }

      &.left {
        background-position: -587% -600%;
      }
    }

    &.p_7_0 {
      background-position: -900.5% -701%;

      &.right {
        background-position: -878.5% -701%;
      }

      &.left {
        background-position: -888.5% -701%;
      }
    }

    &.p_7_1 {
      background-position: -304% -700%;

      &.right {
        background-position: -277.5% -700%;
      }

      &.left {
        background-position: -291% -700%;
      }
    }

    &.p_7_2 {
      background-position: -600% -700%;

      &.right {
        background-position: -573% -700%;
      }

      &.left {
        background-position: -587% -700%;
      }
    }

  }

  &.cab_com {
    .YMan2 {
      position: absolute;
      top: -40%;
      left: 56%;
      transform: translate(-44%, 0);

      height: 103%;
    }

    .Girl02 {
      position: absolute;
      top: -35%;
      left: 50%;
      transform: translate(-50%, 0);

      height: 95%;
    }
  }

  &.cab_eco {
    .YMan2 {
      position: absolute;
      top: -40%;
      left: 56%;
      transform: translate(-44%, 0);

      height: 103%;
    }

    .Girl02 {
      position: absolute;
      top: -35%;
      left: 50%;
      transform: translate(-50%, 0);

      height: 95%;
    }
  }
}

._ap8_seat_com {
  position: relative;

  .info {
    color: white;
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }

  .passenger {
    z-index: 10;
  }
}

._ap8_seat_eco {
  position: relative;

  .info {
    color: white;
    position: absolute;
    font-size: 0.8rem;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
  }

  .passenger {
    z-index: 10;
  }
}


