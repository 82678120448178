
.text-through {
  text-decoration: line-through;
}

.paper {
  .t-red, .t-blue {
    color: black;
    font-weight: bold;
  }
}

.text-info-0 {
  font-size: .8rem;
}

.text-info-1 {
  font-size: 1.0rem;
}

.text-info-2 {
  font-size: 1.2rem;
}

.text-info-margin {
  margin-left: 10px;
}

.tmcWidth-1 {
  min-width: 2.8em;
}

.tmcWidth-2 {
  min-width: 2.0em;
}

.smile-coin {
  height: .8rem;
}

.cursor-pointer {
  cursor: pointer;
}

.dd-flex {
  display: -webkit-box !important;
}

.edit-me {
  min-height: 10rem !important;
  width: 100% !important;
  overflow-y: scroll;
  height: 10rem;
  resize: none;
}

.t-black {
  color: black;
}

.t-white {
  color: white;
}

.t-red {
  color: red;

  a {
    color: red;
  }
}

.t-blue {
  color: blue;
}

.t-green {
  color: green;
}

.t-yellow {
  color: yellow;
}

.char-ani {
  animation: fadeInFromNone2 15s infinite ease-out;
}

.btn-n {
  &-blue {
    background: blue !important;
    color: white !important;
  }

  &-green {
    background: green;
    color: yellow;
  }

  &-red {
    background: red !important;
    color: white !important;
  }

  &-lred {
    background: pink !important;
    color: darkgray !important;
  }
}

.tooltip-inner {
  background: green;
  color: yellow;
  border-radius: 4px;
}

.arrow::before {
  border-top-color: green !important;
  border-bottom-color: green !important;
}

.info-white {
  background: white;
  color: blue;
}

.paper {
  .info-white {
    background: transparent;
    color: black;
  }
}

.paper-dashed-top {
  border-top: 2px dashed black;
}

.paper-dashed-bottom {
  border-bottom: 2px dashed black;
}

@mixin make_line2($prefix,$position, $suffix, $size, $color, $style) {
  .#{$prefix}-#{$position}-#{$style}-#{$suffix} {
    border-#{$position}: $size $style $color;
  }
}

@mixin make_lines2($prefix, $suffix, $size, $color, $style) {
  @include make_line2($prefix, top, $suffix, $size, $color, $style);
  @include make_line2($prefix, bottom, $suffix, $size, $color, $style);
  @include make_line2($prefix, left, $suffix, $size, $color, $style);
  @include make_line2($prefix, right, $suffix, $size, $color, $style);

  .#{$prefix}-#{$style}-#{$suffix} {
    border: $size $style $color;
  }
}

@include make_lines2(paper, h2, 1px, gray, dashed);
@include make_lines2(paper, h1, 2px, black, dashed);
@include make_lines2(paper, h1, 2px, black, solid);
@include make_lines2(paper, h2, 1px, black, solid);

@mixin make_line($prefix,$position, $suffix, $size, $color) {
  .#{$prefix}-#{$position}-#{$suffix} {
    border-#{$position}: $size solid $color;
  }
}

@mixin make_lines($prefix, $suffix, $size, $color) {
  @include make_line($prefix, top, $suffix, $size, $color);
  @include make_line($prefix, bottom, $suffix, $size, $color);
  @include make_line($prefix, left, $suffix, $size, $color);
  @include make_line($prefix, right, $suffix, $size, $color);

  .#{$prefix}-#{$suffix} {
    border: $size solid $color;
  }
}

@include make_lines(paper, h2, 1px, gray);
@include make_lines(paper, h1, 2px, black);
@include make_lines(paper, h1-blue, 2px, blue);
@include make_lines(paper, h1-white, 2px, white);
@include make_lines(paper, h2-white, 1px, white);

@include make_lines(border, gray, 1px, gray);
@include make_lines(border, blue, 1px, blue);

.paper-cell-edge-13 {
  @include make-col(1, 13)
}

$frx_sizes: (
        frx2: 2,
        frx3: 3,
        frx4: 4,
        frx5: 5,
        frx6: 6,
        frx7: 7,
        frx8: 8,
        frx9: 9,
        frx10: 10,
        frx11: 11,
        frx12: 12,
        frx13: 13
);

@each $frx_theme, $frx_column in $frx_sizes {
  @for $i from 1 through $frx_column {
    .frx-col-#{$frx_theme}-#{$i} {
      @include make-col($i, $frx_column);
    }
    .frx-col-#{$frx_theme}-h#{$i} {
      @include make-col((($frx_column)-($i))/2, $frx_column);
    }
  }
}

@mixin make_diagonal_rect($prefix, $color, $fill) {
  .#{$prefix}-#{$color} {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><rect x='2' y='2' width='96' height='96' fill='" + $fill + "' stroke='" + $color + "' stroke-width='6'/><line x1='0' y1='0' x2='100' y2='100' stroke='" + $color + "' stroke-width='6'/><path d='M0 99 L99 0 L100 1 L1 100' fill='transparent' stroke='" + $color + "' stroke-width='6'/></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%, auto;
  }
}

@mixin make_diagonal($prefix, $color_line, $size) {
  .#{$prefix}-#{$color_line} {
    //    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><line x1='0' y1='0' x2='100' y2='100' stroke='" + $color + "' stroke-width='" + $size + "'/><line x1='0' y1='100' x2='100' y2='0' stroke='" + $color + "' stroke-width='" + $size + "'/></svg>");
    //    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='" + $color_line + "' /><path d='M0 99 L99 0 L100 1 L1 100' fill='" + $color_line + "' /></svg>");
    background: url('/img/diag.svg') no-repeat center center;
    background-size: 100% 100%, auto;
  }
}

.childStyle-w {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.childStyle-h {
  position: absolute;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fullScreen {
  position: absolute;
  height: 100%;
  width: 100%;
}

.fragmentStyle {
  position: relative;
  height: 100%;
  width: 100%;
}

.advertisingStyle {
  position: absolute;
  z-index: 1000;
  height: 94%;
  width: 94%;
  top: 3%;
  left: 3%;
  bottom: 1%;
}

.parentStyle {
  position: relative;
  height: 93%;
  width: 93%;
  top: 3%;
  left: 3%;
}

@include make_diagonal_rect(paper-diagonal-h2-y, red, yellow);
@include make_diagonal_rect(paper-diagonal-h2-t, red, transparent);
@include make_diagonal(paper-diagonal-h2, black, 6);
@include make_diagonal(paper-diagonal, black, 2);

.wordBreakAll {
  word-break: break-all;
}

.gray-italic {
  color: gray;
  font-style: italic;
}

.parent {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.child {
  left: 0;
  position: absolute;
}

.child-vertical {
  top: 2rem;
  height: 90%;
  position: absolute;

}

.child-horizontal {
  top: 10%;
  left: 1%;
  right: 1%;
  width: 90%;
  position: absolute;
}

.child-r-b {

  position: absolute;
  right: 0;
  bottom: 0;
}

.child-r-t {
  right: 0;
  top: 0;
  position: absolute;

}

.child-l-t {
  height: 10%;
  left: 0;
  top: 0;
  position: absolute;

}

.hidden {
  position: absolute;
  z-index: 1000;
  visibility: hidden;
}

.sig-panel, .sig-panel-mini {
  cursor: pointer;
  background: #f3f3f3;
  color: blue;
  border: 1px solid lightgrey;
  border-radius: .4rem;

  .block-main {
    font-size: 1.7rem;
    text-align: center;
  }

  .index-bottom {
    font-size: 0.8rem;
    text-align: right;
  }

  /*
  &:hover {
    box-shadow: 0 15px 25px -5px #0057ab;
    transform: scale(1.03);
  }
  */
}

.sig-panel {
  height: 5rem;
}

.sig-panel-mini {
  //  height: 3rem;
}

.sig-panel.selected, .sig-panel-mini.selected {
  animation: anisel2 .3s ease-in-out alternate;
}

@keyframes anisel2 {
  0%, 100% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(0.9);
  }
}

.sig-panel-appear {
  opacity: 0.01;
}

.sig-panel-appear.sig-panel-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.sig-button {
  background: lightgray;
  padding: 0.25rem;
  margin: 0 0.25rem;
  border: gray 1px solid;
}

@import "games/games";

@import "games/floorx";
@import "games/onex";
@import "games/xmt";
@import "games/ft";
@import "games/fb";
@import "games/fd";
@import "games/ap8";
