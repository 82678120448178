$fb--height: 7.3rem;

$fb-cell-height: calc((100vh - #{$fb--height}) / 12);
$fb-col-info-width: percentage(2 / 12);
$fb-col-info-height: percentage(1 / 12);

$fb-green: #3ca24e;
$fb-gray: lightgrey;
$fb-lightblue: #b8edff;
$fb-border: white;

.fb-t-green {
  color: $fb-green;
}

.fb-field-outer {
  position: relative;
  //  background: $fb-green;
  width: 100%;
}

.fb-cell, .fb-cell-selector-active, .fb-cell-selector, .fb-cell-archive, .fb-cell-archive-hb, .fb-cell-archive-h, .fb-cell-archive-b {
  position: relative;
  height: $fb-cell-height;
  min-height: 1.3rem;
  max-height: 2.3rem;
  z-index: 20;
  cursor: pointer;
}

.fb-cell-archive {
  border: 1px solid $fb-border;
  background: $fb-lightblue;
  color: $fb-green;
}

.fb-cell-archive-hb, .fb-cell-archive-h, .fb-cell-archive-b {
  background: $fb-lightblue url('/img/g/fb/grid.svg');
}

.fb-cell-archive-hb {
  border: 3px solid red;
}

.fb-cell-archive-b {
  border: 3px solid $fb-green;
}

.fb-cell-archive-h {
  border: 3px solid yellow;
}

.fb-cell-selector-active, .fb-cell-selector {
  background: yellow;
  border-radius: .3rem;
  cursor: pointer;
}

.fb-cell-selector-active {
  color: red;
  border: 2px solid red;
}

.fb-cell-selector {
  color: $fb-green;
  border: 2px solid $fb-green;
}

.fb-info-active {
  height: $fb-cell-height;
  min-height: 1.3rem;
  max-height: 2.3rem;
  color: $fb-green;
  background: $fb-lightblue;
  border-radius: .3rem;
  border: 2px solid $fb-green;
}

.fb-info {
  height: $fb-cell-height;
  min-height: 1.3rem;
  max-height: 2.3rem;
  border: 1px solid $fb-border;
  color: blue;
  background: $fb-gray;
  position: relative;
}

.fb-info-status {
  background: $fb-green;
  color: white;
  text-align: center;
}

.fb-ball {
  position: absolute;
  z-index: 30;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 80%;
}

.fb-gate-cell, .fb-gate-cell-active {
  height: $fb-cell-height;
  min-height: 1.3rem;
  max-height: 2.3rem;
  border: 1px solid lightgray;
  position: relative;
}

.fb-gate-cell {
  color: $fb-green;
  background: $fb-lightblue url('/img/g/fb/grid.svg');
}

.fb-gate-cell-active {
  color: red;
  background: yellow url('/img/g/fb/grid.svg');
}

.fb-field {
  position: absolute;

  background: $fb-green;

  width: 100% - 2*$fb-col-info-width;
  height: calc(100% - 2 * 0px);
  left: 0;
  top: 0;

  margin: 0 $fb-col-info-width;
}

.fb-ball-point {
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 1.7*$fb-col-info-height;
  z-index: 30;
  height: .55*$fb-col-info-height;
}

.fb-line-middle {
  position: absolute;
  left: 0;
  top: 6.7*$fb-col-info-height;
}

.fb-line-gate {
  position: absolute;
  left: 0;
  top: 2*$fb-col-info-height;
}

.fb-gate {
  position: absolute;

  width: 100% - 2*$fb-col-info-width;
  height: 100%;
  left: 0;
  top: 0;

  margin: 0 $fb-col-info-width;
}

.fb-gate-border-top {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
}

.fb-gate-border-left {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  height: 2*$fb-col-info-height;
}

.fb-gate-border-right {
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  height: 2*$fb-col-info-height;
}

.fb-gate-line-a, .fb-gate-line-b, .fb-gate-line-c, .fb-gate-line-d {
  z-index: 2;
  position: absolute;
  bottom: 1.7*$fb-col-info-height;
}

.fb-gate-line-a {
  right: 50%;
  width: 40%;
  height: 9.5*$fb-col-info-height;
}

.fb-gate-line-d {
  transform: scaleX(-1);
  left: 50%;
  width: 40%;
  height: 9.5*$fb-col-info-height;
}

.fb-gate-line-b {
  right: 50%;
  width: 20%;
  height: 8.5*$fb-col-info-height;
}

.fb-gate-line-c {
  transform: scaleX(-1);
  left: 50%;
  width: 20%;
  height: 8.5*$fb-col-info-height;
}

.fb-keeper-ready {
  position: absolute;
  transform: translate(-50%, -100%);
  left: 50%;
  top: 2*$fb-col-info-height;
  z-index: 3;
  height: 1.5*$fb-col-info-height;
}

.fb-keeper-a, .fb-keeper-b, .fb-keeper-c, .fb-keeper-d {
  z-index: 3;
  position: absolute;
  pointer-events: none;
  height: 1.5*$fb-col-info-height;
}

.fb-keeper-a {
  transform: translate(-50%, 0);
  left: 20%;
  top: .3*$fb-col-info-height;
}

.fb-keeper-d {
  transform: translate(-50%, 0);
  right: 0;
  top: .3*$fb-col-info-height;
}

.fb-keeper-b {
  transform: translate(-50%, -100%);
  left: 25%;
  top: 2*$fb-col-info-height;
}

.fb-keeper-c {
  transform: translate(-50%, -100%);
  right: 10%;
  top: 2*$fb-col-info-height;
}

.fb-ball-a, .fb-ball-b, .fb-ball-c, .fb-ball-d {
  pointer-events: none;
  position: absolute;
  z-index: 5;
  height: .35*$fb-col-info-height;
}

.fb-ball-a {
  transform: translate(-50%, -50%);
  left: 10%;
  top: .5*$fb-col-info-height;
}

.fb-ball-d {
  transform: translate(-50%, -50%);
  right: 10%;
  top: .5*$fb-col-info-height;
}

.fb-ball-b {
  transform: translate(-50%, -50%);
  left: 6%;
  top: 1.7*$fb-col-info-height;
}

.fb-ball-c {
  transform: translate(-50%, -50%);
  right: 7%;
  top: 1.7*$fb-col-info-height;
}

.fb-ball-right, .fb-ball-left {
  position: absolute;
  bottom: 5%;
  height: 70%;
}

.fb-ball-right {
  right: 5%;
}

.fb-ball-left {
  left: 5%;
}

.fb-row {
  z-index: 2;
}

.fb-info-turn-none {
  background: blue;
  color: white;
  text-align: center;
}

.fb-info-turn-success {
  background: blue;
  color: white;
  text-align: center;
}

.fb8-info {
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem 10px 0.5rem;

  .column {
    z-index: 5;

    .cell {
      border: solid 1px white;
      border-radius: 4px;
      font-size: 1.2rem;
      min-width: 3.2rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;

      &.prepare {
        color: white;
      }

      &.title {
        color: white;
        background: rgba(255, 255, 255, 0.25);
        border: solid 1px lightgreen;
      }

      &.current {
        color: red;
      }

      &.win{
        color: grey;
        background: rgba(202, 202, 202, 0.35);
      }

      &.lost{
        color: black;
      }
    }
  }
}
